import React, { useState, useEffect } from "react"
import {
  SiReddit,
  SiInstagram,
  SiKoFi,
  SiPixiv,
  SiTumblr,
  SiDeviantart,
  SiPatreon,
  SiTwitter,
  SiDiscord,
} from "react-icons/si"
import { Wrap, IconButton, Link, Skeleton } from "@chakra-ui/react"
export const Socials = props => {
  const [insta, setInsta] = useState("")
  const [reddit, setReddit] = useState("")
  const [deviant, setDeviant] = useState("")
  const [kofi, setKofi] = useState("")
  const [patreon, setPatreon] = useState("")
  const [tumblr, setTumblr] = useState("")
  const [pixiv, setPixiv] = useState("")
  const [twitter, setTwitter] = useState("")
  const [discord, setDiscord] = useState("")
  const loading = props.isLoaded
  useEffect(() => {
    let accounts = props.accounts
    Object.entries(accounts).map(([key, value]) => {
      switch (key) {
        case "instagram":
          value ? setInsta(value) : console.log("Insta value empty")
          break
        case "twitter":
          value ? setTwitter(value) : console.log("Twitter value empty")
          break
        case "discord":
          value ? setDiscord(value) : console.log("Discord value empty")
          break
        case "deviant":
          value ? setDeviant(value) : console.log("Deviant art empty")
          break
        case "kofi":
          value ? setKofi(value) : console.log("Kofi empty")
          break
        case "tumblr":
          value ? setTumblr(value) : console.log("Tumblr empty")
          break
        case "pixiv":
          value ? setPixiv(value) : console.log("Pixiv empty")
          break
        case "patreon":
          value ? setPatreon(value) : console.log("Patreon empty")
          break
        case "reddit":
          value ? setReddit(value) : console.log("Reddit empty")
          break
        default:
          console.log("No social media accounts.")
          break
      }
      return console.log("Initialized socials")
    })
  }, [props.accounts])

  return (
    <Wrap spacing={2}>
      {insta
        ? (console.log(insta),
          (
            <Link href={insta} isExternal>
              <IconButton
                aria-label={"Instagram Link"}
                icon={<SiInstagram />}
                m={1}
                colorScheme={"whiteAlpha"}
                color={"black"}
                isRound
                borderWidth={"1px"}
                borderColor={"black"}
              />
            </Link>
          ))
        : console.log("Insta not here")}
      {deviant
        ? (console.log(deviant),
          (
            <Link href={deviant} isExternal>
              <Skeleton isLoaded={loading}>
                <IconButton
                  m={1}
                  aria-label={"Deviant Art Link"}
                  icon={<SiDeviantart />}
                  colorScheme={"whiteAlpha"}
                  color={"black"}
                  isRound
                  borderWidth={"1px"}
                  borderColor={"black"}
                />
              </Skeleton>
            </Link>
          ))
        : console.log("Deviant not here")}
      {kofi
        ? (console.log(kofi),
          (
            <Link href={kofi} isExternal>
              <Skeleton isLoaded={loading}>
                <IconButton
                  m={1}
                  aria-label={"Kofi Link"}
                  icon={<SiKoFi />}
                  colorScheme={"whiteAlpha"}
                  color={"black"}
                  borderWidth={"1px"}
                  isRound
                  borderColor={"black"}
                />
              </Skeleton>
            </Link>
          ))
        : console.log("Kofi not here")}
      {tumblr
        ? (console.log(tumblr),
          (
            <Link href={tumblr} isExternal>
              <Skeleton isLoaded={loading}>
                <IconButton
                  aria-label={"Tumblr Link"}
                  m={1}
                  icon={<SiTumblr />}
                  colorScheme={"whiteAlpha"}
                  color={"black"}
                  borderWidth={"1px"}
                  isRound
                  borderColor={"black"}
                />
              </Skeleton>
            </Link>
          ))
        : console.log("Tumblr not here")}
      {pixiv
        ? (console.log(pixiv),
          (
            <Link href={pixiv} isExternal>
              <Skeleton isLoaded={loading}>
                <IconButton
                  aria-label={"Pixiv Link"}
                  m={1}
                  icon={<SiPixiv />}
                  colorScheme={"whiteAlpha"}
                  color={"black"}
                  borderWidth={"1px"}
                  isRound
                  borderColor={"black"}
                />
              </Skeleton>
            </Link>
          ))
        : console.log("Pixiv not here")}
      {patreon
        ? (console.log(patreon),
          (
            <Link href={patreon} isExternal>
              <Skeleton isLoaded={loading}>
                <IconButton
                  aria-label={"Patreon Link"}
                  m={1}
                  icon={<SiPatreon />}
                  colorScheme={"whiteAlpha"}
                  color={"black"}
                  borderWidth={"1px"}
                  isRound
                  borderColor={"black"}
                />
              </Skeleton>
            </Link>
          ))
        : console.log("Patreon not here")}
      {reddit
        ? (console.log(reddit),
          (
            <Link href={reddit} isExternal>
              <Skeleton isLoaded={loading}>
                <IconButton
                  aria-label={"Reddit Link"}
                  m={1}
                  icon={<SiReddit />}
                  colorScheme={"whiteAlpha"}
                  color={"black"}
                  borderWidth={"1px"}
                  isRound
                  borderColor={"black"}
                />
              </Skeleton>
            </Link>
          ))
        : console.log("Reddit not here")}
      {twitter
        ? (console.log(twitter),
          (
            <Link href={twitter} isExternal>
              <Skeleton isLoaded={loading}>
                <IconButton
                  aria-label={"Twitter Link"}
                  m={1}
                  icon={<SiTwitter />}
                  colorScheme={"whiteAlpha"}
                  color={"black"}
                  borderWidth={"1px"}
                  isRound
                  borderColor={"black"}
                />
              </Skeleton>
            </Link>
          ))
        : console.log("Twitter not here")}
      {discord
        ? (console.log(discord),
          (
            <Link href={discord} isExternal>
              <Skeleton isLoaded={loading}>
                <IconButton
                  aria-label={"Discord Link"}
                  m={1}
                  icon={<SiDiscord />}
                  colorScheme={"whiteAlpha"}
                  color={"black"}
                  borderWidth={"1px"}
                  isRound
                  borderColor={"black"}
                />
              </Skeleton>
            </Link>
          ))
        : console.log("Discord not here")}
    </Wrap>
  )
}