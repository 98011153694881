//Create paid subscription option for mail handler, use CardElement from Stripe to handle payment details.
//Use usersubscribed state to not showthe subscribe bar if a user has already subscribed.
//Save subscriptions in localstorage.
import React, { useEffect, useState, ReactElement, useContext } from "react"
import { useForm } from "react-hook-form"
import { ReadMenu } from "../components/menu"
import { navigate, Link as GatsbyLink } from "gatsby"
import SEO from "../components/seo"
import { useDropzone } from "react-dropzone"
import { Elements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js/pure"
import { Stripe } from "@stripe/stripe-js"
import {
  Heading,
  useDisclosure,
  Spinner,
  Box,
  Accordion,
  Link,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Textarea,
  Center,
  Tabs,
  TabPanel,
  TabPanels,
  HStack,
  Text,
  Button,
  VStack,
  Divider,
  FormLabel,
  FormControl,
  Input,
  Select,
  FormHelperText,
  useToast,
  Skeleton,
  Img,
  ModalOverlay,
  Modal,
  ModalContent,
  RadioGroup,
  Radio,
  InputGroup,
  InputRightAddon,
  ButtonGroup,
} from "@chakra-ui/react"
import { Socials } from "../components/buttons"
import fire from "firebase/app"
import "firebase/firestore"
import useFirebase from "../components/useFirebase"
import betaLogo from "../images/lokalcommissionslogobeta2.png"
import { addToMailingList } from "../components/maintenance"
import { FaChevronRight } from "react-icons/fa"
import { StripeDispatchContext, StripeContext } from "../components/stripeutils"

/*const sparkClient = "e573331c44b859b0646910a1df93c8bafe09be31"

const sendMail = (name, email, values) => {
  sparkClient.transmissions
    .send({
      options: {
        sandbox: false,
      },
      content: {
        from: "noreply@mail.lokalcr.com",
        subject: "Hello," + { name },
        html:
          "<html><body><p>Testing SparkPost - the world's most awesomest email service!</p></body></html>",
      },
      recipients: [{ address: email }],
    })
    .then(data => {
      console.log("Woohoo! You just sent your first mailing!")
      console.log(data)
    })
    .catch(err => {
      console.log("Whoops! Something went wrong")
      console.log(err)
    })
}*/
const CommissionPage: React.FC<{ location }> = ({ location }): ReactElement => {
  const firebase = useFirebase()
  /* const stripe = useStripe()
  const elements = useElements()
  Trying an approach where stripe is only loaded on checkout*/
  const toast = useToast()
  const [loaded, setLoaded] = useState(false)

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
  } = useDropzone({
    accept: "image/jpeg, image/png, image/tiff, image/gif, image/jpg",
    onDrop: acceptedFiles => {
      convertImage(acceptedFiles)
    },
  })
  const { handleSubmit, register } = useForm()
  const id: string = location.pathname.slice(3)
  const queryParams = new URLSearchParams(location.search)
  const [refImage, setImage] = useState<string>(null)
  const [inputMail, setEmail] = useState<string>(null)
  const [imagetype, setMime] = useState<string>(null)
  const [pressed, setPress] = useState<boolean>(false)
  const [clientStatus, setStatus] = useState<string>(null)
  const [profilePic, setProfilePic] = useState<string>(null)
  const [userSubscribed, setUserSubscribed] = useState<boolean>(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [popupActive, setPopupActive] = useState<boolean>(true)
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [artist, setArtist] = useState<string>(null)
  const [stripeObject, setStripeObject] = useState<Promise<Stripe>>(null)
  const [formData, setForm] = useState<any>({
    email: "",
    Terms: "",
    name: "",
    allowed: "",
    notAllowed: "",
    currency: "",
    terms: "",
    slots: 0,
    menuHeading: "",
    subscription_id: "",
    subscription_description: "",
    subscription_price: 5,
    contact: {
      deviant: "",
      instagram: "",
      kofi: "",
      pixiv: "",
      reddit: "",
      tumblr: "",
    },
    menu: [{ 0: { service: "", price: 0.1 } }],
    kofi: "kofi",
    paypal: "paypal",
  })
  /*const StripeAccountAuth = useContext(StripeDispatchContext)
  const StripeAccount = useContext(StripeContext)
  console.log("The StripeAccountAuth object is: "+ typeof StripeAccountAuth)
  console.log("The Stripe promise is: "+ JSON.stringify(StripeAccount)) 
  Uncomment if pursuing stripeutils strategy*/
  useEffect(() => {
    //Refactored get user to make two reads, one to usernames collection to get user doc and a second to get the nanoid to find user in firestore.
    firebase
      ? firebase
          .firestore()
          .collection("usernames")
          .doc(id.toLowerCase())
          .get()
          .then(doc => {
            if (doc.exists) {
              let response = doc.data()
              setArtist(response.id)
              const pathRef = firebase.storage().ref()
              const profilePicRef = pathRef.child(id + "/profile.jpg")
              firebase
                .firestore()
                .collection("artists")
                .doc(response.id)
                .collection("details")
                .doc("form")
                .get()
                .then(async doc => {
                  doc.exists
                    ? await setForm(doc.data())
                    : console.log("No doc found")

                  firebase.analytics().logEvent("view", { user: id })

                  // Get the download URL
                  profilePicRef
                    .getDownloadURL()
                    .then(url => {
                      setProfilePic(url)
                    })
                    .catch(error => {
                      // A full list of error codes is available at
                      // https://firebase.google.com/docs/storage/web/handle-errors
                      switch (error.code) {
                        case "storage/object-not-found":
                          // File doesn't exist
                          setProfilePic(betaLogo)
                          break
                        case "storage/unauthorized":
                          // User doesn't have permission to access the object
                          setProfilePic(betaLogo)
                          break
                        case "storage/canceled":
                          // User canceled the upload
                          setProfilePic(betaLogo)
                          break

                        // ...

                        case "storage/unknown":
                          // Unknown error occurred, inspect the server response
                          setProfilePic(betaLogo)
                          break
                      }
                    })
                  setLoaded(true)
                })
            }
          })
      : console.log("loading")
  }, [firebase])
  useEffect(() => {
    console.log("Stripe account is:" + formData.stripe_account)
    formData.stripe_account
      ? setStripeObject(
          loadStripe(
            /* Test key: "pk_test_51IUP1fHNrfBMaHRqM943h31QcPYSrP7AP3gi9drqXEhBmHiNDdX8DF0u2U7fwVBV8BadM4GJrZdNEDDuGpWu4K0y006axRfVh6"*/ "pk_live_51IUP1fHNrfBMaHRqS3fTxmHi6AqNRDz944Lv47JXEmZTcGyjS0oaOKBG9IGKpfXnCKsMhQuveCFDyzcKAwaBx0zF00f4PMm7nE",
            { stripeAccount: formData.stripe_account }
          )
        )
      : console.log("No stripe account attached to user.")
    formData.name
      ? queryParams.get("session_id")
        ? toast({
            title: "Subscription Successful",
            description: "Thanks for subscribing to " + formData.name,
            status: "success",
            duration: 9000,
            isClosable: true,
          })
        : console.log("No subscription.")
      : console.log("Name not loaded yet.")
    setEmail(localStorage.getItem("subscriber_email"))
  }, [formData])
  /*
  useEffect(() => {
    let sessionId = queryParams.get("session_id")
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sessionId: sessionId,
        user: formData.stripe_account,
      }),
    }
    sessionId
      ? fetch(
          "https://www.lokalcr.com/.netlify/functions/check-session",
          requestOptions
        )
          .then(response => response.json())
          .then(res => {
            setCustomerId(res.customer)
            setStatus(res.status)
          })
          .then(() => handleSubscription())
          .catch(err => console.log(err))
      : console.log("No query params.")
  }, [])
*/
  const handleSubscription = async () => {
    const result = clientStatus

    if (result === "unpaid") {
      // Show error to your customer (e.g., insufficient funds)
      console.log("Payment Error")
    } else {
      // The payment has been processed!
      if (result === "paid") {
        await addToMailingList(inputMail, artist, "paid", firebase)
        toast({
          title: "Subscription Successful",
          description: "Thanks for subscribing to " + formData.name,
          status: "success",
          duration: 9000,
          isClosable: true,
        })
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  }

  const convertImage = image => {
    //Use if switching back to input tags let file = image.target.files[0]
    let file = image[0]
    console.log("Going to upload" + file)
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        console.log(reader.result)
        let base64type = (reader.result as string).split(",")[0]
        let mimeType = base64type.match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0]
        let base64image = (reader.result as string).split(",")[1]
        setMime(mimeType)
        setImage(base64image)
      }
      reader.onerror = function (error) {
        console.log("Error: ", error)
      }
    }
  }
  const finish = (data, email: string) => {
    const submitWorked = res => {
      console.log(res)
      toast({
        title: "Submission Successful",
        description: "Thanks for submitting your commission request.",
        status: "success",
        duration: 9000,
        isClosable: true,
      })
      firebase
        .firestore()
        .collection("artists")
        .doc(artist)
        .update({ submissions: fire.firestore.FieldValue.increment(1) })
    }
    const fields = {
      name: data.email,
      menu: data.menu,
      email: email,
      picture: refImage,
      mime: imagetype,
      payment: data.payment,
      notes: data.notes,
    }
    setPress(true)
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(fields),
    }
    fetch("https://www.lokalcr.com/.netlify/functions/mail", requestOptions)
      .then(response => submitWorked(response))
      .catch(err =>
        toast({
          title: "Submission Failed",
          description: err,
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      )
  }
  const onSubmit = values => {
    console.log(values)
    finish(values, formData.email)
  }

  console.log(formData)
  const SubscriptionFlow: React.FC<any> = (): ReactElement => {
    //Use useStripe hook here.
    const stripe = useStripe()
    const handlePay = () => {
      //Handle subscription type here, paid or null.

      if (value === "monthly") {
        console.log("Paid subscription registration")
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            price: formData.subscription_id,
            currency: formData.currency,
            user: formData.stripe_account,
            name: formData.email,
            artistId: artist,
            logo: profilePic,
            origin: location.href,
            customer: inputMail,
          }),
        }
        fetch(
          "https://www.lokalcr.com/.netlify/functions/paying",
          requestOptions
        )
          .then(response => response.json())
          .then(res => {
            setStatus(res.status)
            console.log(res)
            localStorage.setItem("subscriber_email", inputMail)
            return stripe.redirectToCheckout({ sessionId: res.id })
          })
          .then(result => {
            // If `redirectToCheckout` fails due to a browser or network
            // error, you should display the localized error message to your
            // customer using `error.message`.
            if (result.error) {
              alert(result.error.message)
            }
            //Get client secret from successurl and set   subscription.
          })
          .catch(err =>
            toast({
              title: "Payment Failed",
              description: "Failed to complete payment.",
              status: "error",
              duration: 9000,
              isClosable: true,
            })
          )
      } else {
        console.log("Adding to free mailing list.")
        addToMailingList(inputMail, artist, undefined, firebase)
          .then(() => {
            toast({
              title: "Free Subscription Successful",
              description: "Thanks for subscribing to " + formData.name,
              status: "success",
              duration: 9000,
              isClosable: true,
            })
            localStorage.setItem("subscriber_email", inputMail)
          })
          .catch(err =>
            toast({
              title: "Subscription Failed",
              description: "Failed to subscribe.",
              status: "error",
              duration: 9000,
              isClosable: true,
            })
          )
      }
      onClose
    }
    useEffect(() => {
      formData.subscription_id
        ? console.log("Artist has paid subscriptions.")
        : setValue(null)
    }, [formData])

    const [value, setValue] = useState<any>("monthly")
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <VStack m={10} p={10}>
            <Heading>Choose a membership plan</Heading>
            <RadioGroup
              value={value}
              onChange={setValue}
              name={"subscription-type"}
            >
              <Radio value={"monthly"} disabled={!formData.subscription_id}>
                ${formData.subscription_price} Monthly
              </Radio>
              <Radio value={"free"}>None</Radio>
            </RadioGroup>
            <Text>{formData.subscription_description}</Text>
            <Button bg={"#F6DD8F"} onClick={() => handlePay()}>
              {value == "monthly" ? "JOIN" : "CONTINUE WITHOUT PAYING"}
            </Button>
            <Text color={"gray"}>
              By registering you confirm that you have read and agree to the{" "}
              <Link as={GatsbyLink} to={"/privacy"} color={"#F68F8F"}>
                Privacy Policy
              </Link>
              and{" "}
              <Link as={GatsbyLink} to={"/terms"} color={"#F68F8F"}>
                Terms and Conditions
              </Link>
            </Text>
          </VStack>
        </ModalContent>
      </Modal>
    )
  }
  const Popup: React.FC<any> = (): ReactElement => {
    return (
      <>
        <VStack px={10} bg={"#F2D6D3"} overflow={"hidden"} minH={"100vh"}>
          <SEO title={formData.name} description={formData.description} />
          <HStack
            py={5}
            mx={"auto"}
            my={10}
            px={10}
            bg={"white"}
            justify={"center"}
            borderRadius={"lg"}
            maxW={{ base: "100%", md: "80%", lg: "50%" }}
            wrap={{ base: "wrap", md: "nowrap" }}
          >
            <Img
              src={profilePic}
              my={"auto"}
              boxSize={{ base: "180px", lg: "260px" }}
              borderRadius={"md"}
            />
            <Heading mt={0} mb={2} alignSelf={"start"}>
              {formData.name}
            </Heading>
          </HStack>
          <Center>
            <VStack>
              <Text>{formData.description}</Text>
              <HStack
                bg={"white"}
                my={5}
                p={3}
                mx={"auto"}
                shadow="md"
                borderWidth="1px"
                borderRadius="md"
              >
                <InputGroup>
                  <Input
                    name="joinMailPopup"
                    type="email"
                    id="joinMailPopup"
                    placeholder={"Email"}
                    defaultValue={inputMail || ""}
                    onBlur={e => setEmail(e.target.value)}
                    aria-describedby={"Email Address for mailing list"}
                  />
                  <InputRightAddon
                    p={0}
                    children={
                      <Button bg={"#F6DD8F"} onClick={onOpen}>
                        SUBSCRIBE
                      </Button>
                    }
                  />
                </InputGroup>
              </HStack>

              <Button
                variant={"link"}
                color={"black"}
                onClick={() => setPopupActive(false)}
                mt={20}
              >
                CHECK OUT MY PAGE <FaChevronRight />
              </Button>
            </VStack>
          </Center>
        </VStack>
        <HStack
          justify={"start"}
          align={"center"}
          mt={"-9.5vh"}
          ml={5}
          wrap={{ base: "wrap", md: "nowrap" }}
          height={"7vh"}
        >
          <Img
            src={profilePic}
            my={"auto"}
            boxSize={"7vh"}
            borderRadius={"md"}
            display={"inline-block"}
          />
          <VStack spacing={0} my={"auto"} display={"inline-block"}>
            <Text fontWeight={"bold"} fontSize={"sm"} my={0} py={0}>
              A Lokal site by{" "}
            </Text>
            <Text fontSize={"xl"} fontWeight={"bold"} my={0} py={0}>
              {formData.name}
            </Text>
          </VStack>
        </HStack>
      </>
    )
  }
  return (
    <Elements stripe={stripeObject}>
      <SubscriptionFlow />
      {loaded ? (
        popupActive ? (
          <Popup />
        ) : (
          <VStack px={10} bg={"#F2D6D3"} overflow={"hidden"} minH={"100vh"}>
            <ButtonGroup
              mt={2}
              justify={"space-between"}
              variant={"outline"}
              borderRadius={"md"}
              borderColor={"black"}
            >
              <Button onClick={() => setTabIndex(0)}> ABOUT</Button>
              <Button onClick={() => setTabIndex(1)}>COMMISSIONS</Button>
            </ButtonGroup>
            <Tabs index={tabIndex} onChange={setTabIndex}>
              <TabPanels>
                <TabPanel>
                  {/*About page*/}
                  <Box
                    bg={"white"}
                    my={5}
                    p={3}
                    mx={"auto"}
                    wrap={"wrap"}
                    shadow="md"
                    borderWidth="1px"
                    borderRadius="md"
                  >
                    <VStack>
                      <Img
                        src={profilePic}
                        boxSize={"140px"}
                        borderRadius={"md"}
                      />
                      <Heading>{formData.name}</Heading>
                      <Socials
                        accounts={formData.contact || [{}]}
                        isLoaded={loaded}
                      />
                      <Text>{formData.description}</Text>
                    </VStack>

                    <HStack spacing={0} justify={"space-evenly"}>
                      <VStack p={5}>
                        <Heading as={"h4"} size={"md"}>
                          Will do
                        </Heading>

                        <Text>{formData.allowed}</Text>
                      </VStack>
                      <Divider orientation={"vertical"} />
                      <VStack p={5}>
                        <Heading as={"h4"} size={"md"}>
                          Won't Do
                        </Heading>

                        <Text>{formData.notAllowed}</Text>
                      </VStack>
                    </HStack>
                  </Box>
                  <Accordion allowToggle>
                    <Center>
                      <AccordionItem bg={"white"}>
                        <AccordionButton>
                          <Heading as={"h4"} size={"md"}>
                            Terms and Conditions
                          </Heading>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel>{formData.terms}</AccordionPanel>
                      </AccordionItem>
                    </Center>
                  </Accordion>

                  <HStack
                    bg={"white"}
                    my={5}
                    p={3}
                    mx={"auto"}
                    shadow="md"
                    borderWidth="1px"
                    borderRadius="md"
                  >
                    <InputGroup>
                      <Input
                        name="joinMail"
                        type="email"
                        id="joinMail"
                        placeholder="Email"
                        defaultValue={inputMail || ""}
                        onBlur={e => setEmail(e.target.value)}
                        aria-describedby={"Email Address for mailing list"}
                      />
                      <InputRightAddon
                        p={0}
                        children={
                          <Button onClick={onOpen} bg={"#F6DD8F"}>
                            SUBSCRIBE
                          </Button>
                        }
                      />
                    </InputGroup>
                  </HStack>
                </TabPanel>

                <TabPanel>
                  {/*Commissions Page  */}
                  <VStack w={"80vw"}>
                    <Box
                      bg={formData.slots ? "green.300" : "tomato"}
                      maxW={"80vw"}
                      px={5}
                      py={3}
                      m={5}
                      borderWidth="1px"
                      borderColor={formData.slots ? "green.300" : "tomato"}
                      borderRadius="md"
                      boxShadow="md"
                      color={formData.slots ? "black" : "white"}
                      textTransform={"uppercase"}
                      fontWeight={"bold"}
                    >
                      Open Slots: {formData.slots ? formData.slots : 0}
                    </Box>
                    {formData.menu ? (
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <ReadMenu
                          register={register}
                          head={formData.menuHeading}
                          field={formData.menu || [{}]}
                        />

                        <Box
                          bg={"white"}
                          my={5}
                          p={3}
                          mx={"auto"}
                          shadow="md"
                          borderWidth="1px"
                          borderRadius="md"
                          w={{ base: "90vw", md: "80vw", lg: "60vw" }}
                        >
                          <FormControl isRequired>
                            <FormLabel>Available Payment Methods</FormLabel>
                            <FormHelperText>
                              How would you like to pay?
                            </FormHelperText>

                            <Select name={"payment"} ref={register}>
                              {formData.paypal ? (
                                <option value={"Paypal"}>Paypal</option>
                              ) : (
                                console.log("No Paypal")
                              )}
                              {formData.kofi ? (
                                <option value={"KoFi"}>KoFi</option>
                              ) : (
                                console.log("")
                              )}
                            </Select>
                          </FormControl>
                          <FormControl isRequired>
                            <FormLabel htmlFor="email">Email Address</FormLabel>
                            <Input
                              ref={register}
                              name="email"
                              type="email"
                              id="email"
                              placeholder="Email"
                              aria-describedby={"Email Address"}
                            />
                          </FormControl>
                          <FormControl>
                            <FormLabel>Description</FormLabel>
                            <FormHelperText id="notes-helper-text">
                              Add anything extra you'd like to ask the artist.
                            </FormHelperText>
                            <Textarea
                              ref={register}
                              name="notes"
                              id="notes"
                              aria-describedby="notes-helper-text"
                              placeholder={"Describe your commission"}
                            />
                          </FormControl>
                          <FormControl>
                            <FormLabel>Reference</FormLabel>
                            <FormHelperText>
                              If you have a reference image, add it here.
                            </FormHelperText>
                            <Box
                              {...getRootProps()}
                              p={3}
                              borderWidth={"1px"}
                              bg={refImage ? "green.300" : "#F6DD8F"}
                            >
                              <Center>
                                {refImage
                                  ? "Image Uploaded Successfully"
                                  : "Drop your reference image here"}
                              </Center>

                              <input
                                type="file"
                                name={"picture"}
                                ref={register}
                                accept={".jpeg, .png, .jpg, .tiff, .gif"}
                                {...getInputProps()}
                              />
                            </Box>
                          </FormControl>
                          <Center>
                            <Button
                              type={"submit"}
                              disabled={pressed}
                              bg={"#F6DD8F"}
                              m={5}
                            >
                              Submit
                            </Button>
                          </Center>
                        </Box>
                      </form>
                    ) : (
                      <Text>
                        This artist doesn't have a commission menu available.
                      </Text>
                    )}
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        )
      ) : (
        <Center>
          <Spinner mt={80} />
        </Center>
      )}
    </Elements>
  )
}

export default CommissionPage
